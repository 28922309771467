import { useEffect, useState } from "react";

export const ButtonSection: React.FC<{setSectionFilter : any, section: number, img : string, sectionName : string, className : string}> = (props) => {

    const [isClicked, setIsClicked] = useState(false);

    useEffect(()=>{
        props.setSectionFilter(props.section,!isClicked);
    }, [props, isClicked]);

    function toggleButton() {
        setIsClicked(!isClicked);
    }

    return (
        <button className={`btn btn-light ${props.className} ${isClicked ? 'btn-clicked' : ''}`} type="button" onClick={toggleButton}>
            <img src={props.img} className="btn-section-img" alt={props.sectionName}/>
        </button>
    )
}