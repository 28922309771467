import UserDetailModel from "../models/UserDetailModel";
import { UserDetailRow } from "./UserDetailRow";

export const ModalUsers: React.FC<{userDetails : UserDetailModel[]}> = (props) => {
    return ( 
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-4">
            <div className="modal-dialog modal-dialog-centered modal-lg"  role="document">
                <div className="modal-content word-break max-height-50">
                    <div id="modal_users_body" className="modal-body overflow-y-auto">
                        {props.userDetails.map(userDetail => {
                            return (
                                <UserDetailRow key={`${userDetail.clusterId}_${userDetail.section}`} userDetail={userDetail}/>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}