import boot from './..\\Images\\Areas\\boot.svg';
import compass from './..\\Images\\Areas\\compass.svg';
import pocket_knife from './..\\Images\\Areas\\pocket_knife.svg';
import constelation from './..\\Images\\Areas\\constelation.svg';
import rope from './..\\Images\\Areas\\rope.svg';
import bonfire from './..\\Images\\Areas\\bonfire.svg';
import carabiner from './..\\Images\\Areas\\carabiner.svg';
import clover from './..\\Images\\Areas\\clover.svg';
import { useEffect, useState } from "react";

export const AreaIcon: React.FC<{ area : number, setTitle : any }> = (props) => {
    const [icon, setIcon] = useState<string>();
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        switch(props.area){
            case 1:
                setIcon(boot);    
                if(props.setTitle) props.setTitle("Bota");
                setTitle("Bota");
                break;
            case 2:
                setIcon(compass);   
                if(props.setTitle) props.setTitle("Bússola");
                setTitle("Bússola");
                break;
            case 3:
                setIcon(pocket_knife);    
                if(props.setTitle) props.setTitle("Canivete");
                setTitle("Canivete");
                break;
            case 4:
                setIcon(constelation);    
                if(props.setTitle) props.setTitle("Constelação");
                setTitle("Constelação");
                break;
            case 5:
                setIcon(rope);    
                if(props.setTitle) props.setTitle("Corda");
                setTitle("Corda");
                break;
            case 6:
                setIcon(bonfire);    
                if(props.setTitle) props.setTitle("Fogueira");
                setTitle("Fogueira");
                break;
            case 7:
                setIcon(carabiner);    
                if(props.setTitle) props.setTitle("Mosquetão");
                setTitle("Mosquetão");
                break;
            case 8:
                setIcon(clover);    
                if(props.setTitle) props.setTitle("Trevo");
                setTitle("Trevo");
                break;
        }
    }, [props]);
    
    return (
        <img src={icon} alt={title} className="mr-10" width="48" height="44"/>               
    )
}