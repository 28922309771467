import { AreaIcon } from "./AreaIcon"
import AdminChallengeDetailModel from "../models/AdminChallengeDetailModel";
import { SectionIcon } from "./SectionIcon";
import { ModalSpinnerLoading } from "./ModalSpinnerLoading";

export const ModalChallengeDetail: React.FC<{isLoadingModal : boolean, adminChallengeDetail : AdminChallengeDetailModel}> = (props) => {
    return ( 
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-3">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content word-break">
                    {props.isLoadingModal ?
                            <ModalSpinnerLoading/>
                    :
                        <>
                            <div className="modal-header">
                                <AreaIcon area={props.adminChallengeDetail.challengeField} setTitle={null}/>
                                <h4 className="modal-title text-primary overflow-break">{props.adminChallengeDetail.challengeTitle}</h4>
                                <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <p><strong>Descrição: </strong>{props.adminChallengeDetail.challengeDescription}</p>
                                    </div>  
                                    <div className="col-auto">
                                        <SectionIcon section={props.adminChallengeDetail.challengeSection} setTitle={null}/>
                                    </div>  
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-light" type="button" data-bs-dismiss="modal">OK</button> 
                            </div>
                        </>
                    }       
                </div>
            </div>
        </div>
    )
}