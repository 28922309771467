class SubmissionRejectionModel {
    submissionId: number;
    feedback: string;

    constructor(submissionId : number, feedback : string) {
        this.submissionId = submissionId;
        this.feedback = feedback;
    }
}

export default SubmissionRejectionModel;