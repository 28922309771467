import { ModalRejectSubmission } from './ModalRejectSubmission';
import { Submission } from './Submission';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { SpinnerLoading } from './Utils/SpinnerLoading';
import { ErrorOverlay } from './Utils/ErrorOverlay';
import SubmissionDetail from '../models/SubmissionDetail';
import SubmissionRejectionModel from '../models/SubmissionRejectionModel';
import { ModalAcceptSubmission } from './ModalAcceptSubmission';

export const Submissions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [httpError, setHttpError] = useState(null);
    const [sectionFilter, setSectionFilter] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [submissionId, setSubmissionId] = useState(-1);
    const [pendingSubmissions, setPendingSubmissions] = useState<SubmissionDetail[]>([]);

    const {authState} = useOktaAuth();

    //get submissions
    useEffect(() => {
        const fetchSubmissions = async () => {
            setForceUpdate(false);
            setIsLoading(true);
            if (!authState || !authState.isAuthenticated || authState?.accessToken?.claims.userType !== "admin"){
                setIsLoading(false);
                return;
            }

            let url = `https://5picos.coimbra.escutismo.pt/api/submissions/secure/admin`;
            if (sectionFilter !== 0) {
                url += "/section/" + sectionFilter;
            }
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();

            const loadedSubmissions: SubmissionDetail[] = [];
            for(const key in responseJson){
                loadedSubmissions.push({
                    submissionId : responseJson[key].submissionId,
                    submissionComment : responseJson[key].submissionComment,
                    challengeTitle : responseJson[key].challengeTitle,
                    challengeDescription : responseJson[key].challengeDescription,
                    challengeSection : responseJson[key].challengeSection,
                    challengeField : responseJson[key].challengeField,
                    clusterDescriptor : responseJson[key].clusterDescriptor,
                    fileIds : responseJson[key].fileIds
                });
            }
            setPendingSubmissions(loadedSubmissions);

            setIsLoading(false);
        };
        fetchSubmissions().catch((error:any) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    }, [authState,sectionFilter,forceUpdate]);

    //reject submission
    const rejectSubmission = async (feedback: string) => {
        try {
            if (!authState || !authState.isAuthenticated || authState?.accessToken?.claims.userType !== "admin"){
                return;
            }
            setIsLoadingModal(true);

            const submissionRejectionModel = new SubmissionRejectionModel(submissionId, feedback);
            const url = `https://5picos.coimbra.escutismo.pt/api/submissions/secure/admin/reject`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(submissionRejectionModel)
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok){
                throw new Error("Não foi possível rejeitar a submissão.");
            } else {
                setSubmissionId(-1);
            }

            setIsLoadingModal(false);
        } catch (error:any) {
            setIsLoadingModal(false);
            setHttpError(error.message);
        }
    };

    //accept submission
    const acceptSubmission = async () => {
        try {
            if (!authState || !authState.isAuthenticated || authState?.accessToken?.claims.userType !== "admin"){
                return;
            }
            setIsLoadingModal(true);

            const url = `https://5picos.coimbra.escutismo.pt/api/submissions/secure/admin/accept/${submissionId}`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const response = await fetch(url, requestOptions);
            if (!response.ok){
                throw new Error("Não foi possível aceitar a submissão.");
            } else {
                setSubmissionId(-1);
            }

            setIsLoadingModal(false);
        } catch (error:any) {
            setIsLoadingModal(false);
            setHttpError(error.message);
        }
    };

    if (httpError) {
        return (
            <ErrorOverlay httpError={httpError}/>
        );
    }

    return (
        <>
            {isLoading &&  <SpinnerLoading/>}
            <ModalRejectSubmission isLoadingModal={isLoadingModal} setForceUpdate={setForceUpdate} rejectSubmission={rejectSubmission}/>
            <ModalAcceptSubmission isLoadingModal={isLoadingModal} setForceUpdate={setForceUpdate} acceptSubmission={acceptSubmission}/>
            <section className="d-flex mt-150 flex-column justify-content-center align-items-center">
                <div className="container d-flex flex-row-reverse">
                    <select className="w-auto form-select mb-4 border-button" id="section-filter" onChange={e => setSectionFilter(+e.target.value)}>
                        <option value="0">Tudo</option>
                        <option value="1">Lobitos</option>
                        <option value="2">Exploradores</option>
                        <option value="3">Pioneiros</option>
                        <option value="4">Caminheiros</option>
                    </select>
                </div>
                <div className="container mb-5">
                    {pendingSubmissions.map((submission) => {
                        return <Submission key={submission.submissionId} submission={submission} setSubmissionId={setSubmissionId}/>
                    })}
                </div>
            </section>
        </>
    );
} 