import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";

export const IntroText: React.FC<{}> = (props) => {
    const {oktaAuth} = useOktaAuth();
    
    return (
        <section className="d-flex justify-content-center align-items-center justify-content-center align-items-center gradient-bg">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="text-center d-flex flex-column justify-content-center align-items-center mt-5pct mb-5">
                            <div className="display-4 mb-5 w-100">
                                <span className="fw-bold highlight text-white">Onde</span>
                                &nbsp;
                                <span className="fw-bold highlight text-white">queres ir?</span>
                                <br/>
                                <span className="fw-bold highlight text-white">Quanto queres</span>
                                &nbsp;
                                <span className="fw-bold highlight text-white">arriscar?</span>
                            </div>
                            <div className="text-center d-flex flex-column justify-content-center align-items-center">
                                <p id="intro" className="mb-5">
                                Enquanto escuteiros, há sempre algo que nos desafia.<br/><br/>
                                É fácil que, na nossa cabeça, surjam memórias de raids exigentes, de tendas montadas em construções elevadas, de jangadas que construímos para descer o rio ou mesmo de momentos de partilha à volta de uma fogueira numa noite fria.<br/><br/>
                                Com todos os desafios que vamos superando, vamos crescendo sempre mais um pouco. Vamo-nos tornando mais corajosos, fortes e ousados. Deixamos de ter medo de dizer “sim” a desafios cada vez maiores, mesmo que estes saiam completamente da nossa zona de conforto.<br/><br/>
                                A Secretaria Regional do Método Escutista traz até vós a dinâmica dos Cinco Picos!<br/><br/>
                                Esta dinâmica, tal como tantas outras coisas no Escutismo, pretende desafiar os nossos Lobitos e Escuteiros através de um grande jogo que envolve todos os Agrupamentos da Região.<br/><br/>
                                O grande desafio está feito. Agora está do vosso lado dizer o “sim”!
                                </p>
                            </div>
                            <Link type="button" className="btn btn-primary text-white fs-5 py-2 px-4 border-button" 
                                to={oktaAuth ? `/challenges` : `/login`}>
                                Aceitamos o Desafio!
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}