import './App.css';
import { Navbar } from './layouts/NavbarAndFooter/Navbar';
import { Footer } from './layouts/NavbarAndFooter/Footer';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { oktaConfig } from './lib/oktaConfig';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import LoginWidget from './Auth/LoginWidget';
import { MapPicos } from './layouts/MapPicos';
import { Challenges } from './layouts/Challenges';
import { Submissions } from './layouts/Submissions';

import bg_overlay from './Images\\overlay.svg';
import { IntroText } from './layouts/IntroText';
import { FAQ } from './layouts/FAQ';

const oktaAuth = new OktaAuth(oktaConfig);

export const App = () => {
  const customAuthHandler = () => {
    history.push("/login");
  }

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }

  return (
    <>
      <img id="background-overlay" alt="" src={bg_overlay}/> 
      <div className='d-flex flex-column min-vh-100 gradient-bg'>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
          <Navbar/>
          <div className='flex-grow-1'>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/home"/>
              </Route>
              <Route path="/home">
                <MapPicos/>
                {/*<IntroVideo video_url="https://www.youtube.com/embed/FHx82fJWShk"/>*/}
                <IntroText/>
              </Route>
              <Route path="/faq">
                <FAQ/>
              </Route>
              <SecureRoute path="/challenges">
                <Challenges/>
              </SecureRoute>
              <SecureRoute path="/submissions">
                <Submissions/>
              </SecureRoute>
              {/*<Route path="/reviewlist/:bookId">
                <ReviewListPage/>
              </Route>*/}
              <Route path="/login" render={() => <LoginWidget config={oktaConfig}/>} />
              <Route path="/login/callback" component={LoginCallback}/>
            </Switch>
          </div>
          <Footer/>
        </Security>
      </div>
    </>
  );
}

