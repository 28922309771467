import { useEffect, useRef, useState } from "react"
import UserChallengeSubmissionModel from "../models/UserChallengeSubmissionModel"
import { AreaIcon } from "./AreaIcon"
import { ModalSpinnerLoading } from "./ModalSpinnerLoading";

export const ModalChallenge: React.FC<{isLoadingModal : boolean, userChallengeSubmission : UserChallengeSubmissionModel, submitSubmission : any, forceUpdateChallenges : any}> = (props) => {
    const [submitButton, setSubmitButton] = useState(false);
    const [commentary, setCommentary] = useState('');
    const [files, setFiles] = useState([]);
    const [invalidMessage, setInvalidMessage] = useState('');
    const formRef = useRef<HTMLFormElement>(null);
    const modalRef = useRef<HTMLButtonElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    //submit form
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (files.length === 0) {
            if(fileInputRef.current){
                fileInputRef.current.classList.remove('is-valid');
                fileInputRef.current.classList.add('is-invalid');
                setInvalidMessage('Por favor seleccione pelo menos um ficheiro.');
            } 
        } else { 
            props.submitSubmission(props.userChallengeSubmission.challengeId,(props.userChallengeSubmission.submissionId ? props.userChallengeSubmission.submissionId : null), commentary, files).then(() => {
                hideModal();
                if (formRef.current) {
                    formRef.current.reset();
                }
                props.forceUpdateChallenges(true);
            })
        }
    };

    //close modal
    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.click();
        }
    };

    //reset form validation
    const InvalidateForm = () => {
        if(fileInputRef.current){
            fileInputRef.current.classList.remove('is-valid');
            fileInputRef.current.classList.remove('is-invalid');
        } 
    }

    //validate input files
    const checkFilesValidity = (target : any) => {
        const tempFiles = target.files;
        const validFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

        let totalFileSize = 0;
        
        if (tempFiles.length > 3){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
            setInvalidMessage('Por favor seleccione no máximo 3 ficheiros.'); 
            return;
        }

        for (var file in tempFiles) {
            if(file !== "item" && file !== "length"){
                totalFileSize += +((tempFiles[file].size/1024)/1024).toFixed(4);
                if (!validFileTypes.includes(tempFiles[file].type)){
                    target.classList.remove('is-valid');
                    target.classList.add('is-invalid');
                    setInvalidMessage('Por favor seleccione apenas ficheiros do tipo png ou jpg/jpeg.'); 
                    return;
                }
            }
        }

        if (totalFileSize > 10){
            target.classList.remove('is-valid');
            target.classList.add('is-invalid');
            setInvalidMessage('Por favor seleccione ficheiros com um tamanho total máximo de 10MB.');
            return;
        }

        target.classList.remove('is-invalid');
        target.classList.add('is-valid');
        setFiles(tempFiles);
    }
    
    //change footer buttons accordingly
    useEffect(() => {
        if (props.userChallengeSubmission.stateId === undefined || props.userChallengeSubmission.stateId === 4)
            setSubmitButton(true);
        else 
            setSubmitButton(false);
    }, [props.userChallengeSubmission.stateId]);

    return (
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-1">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content word-break">
                    {props.isLoadingModal && <ModalSpinnerLoading/>}
                    <form ref={formRef} onSubmit={handleSubmit} className={`needs-validation ${props.isLoadingModal && `d-none`}`}  noValidate>
                        <div className="modal-header">
                            <AreaIcon area={props.userChallengeSubmission.challengeField} setTitle={null}/>
                            <h4 className="modal-title text-primary overflow-break">{props.userChallengeSubmission.challengeTitle}</h4>
                            <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            {props.userChallengeSubmission.stateId === 2 &&
                                <div className="alert alert-primary" role="alert">
                                    <span>
                                        <strong><span className="color-primary text-align-center">Prova submetida! Aguardem a validação.</span></strong><br/>
                                    </span>
                                </div>
                            }  
                            {props.userChallengeSubmission.stateId === 3 &&
                                <div className="alert alert-success" role="alert">
                                    <span>
                                        <strong><span className="color-success text-align-center">Prova aprovada!</span></strong><br/>
                                    </span>
                                </div>
                            }  
                            {props.userChallengeSubmission.stateId === 4 &&
                                <div className="alert alert-warning" role="alert">
                                    <span>
                                        <strong><span className="color-yellow text-align-center">Prova rejeitada</span></strong><br/>
                                        <strong><span className="color-yellow">Motivo: </span></strong>
                                        {props.userChallengeSubmission.submissionFeedback}
                                    </span>
                                </div>
                            }       
                            <p><strong>Descrição: </strong>{props.userChallengeSubmission.challengeDescription}</p>
                            <div className="vstack">
                                {(props.userChallengeSubmission.stateId === undefined || props.userChallengeSubmission.stateId === 4) ?
                                    <>
                                        <div className="form-floating mb-3">
                                            <textarea id="form-submission-commentary" placeholder="Comentário" className="form-control h-120" maxLength={255} onChange={e => setCommentary(e.target.value)}></textarea>
                                            <label htmlFor="form-submission-commentary">Comentário</label>
                                        </div>
                                        <div>
                                            <label htmlFor="form-submission-files" className="form-label">Provas de Conclusão</label>
                                            <input ref={fileInputRef} className="form-control" type="file" id="form-submission-files" onChange={e => checkFilesValidity(e.target)} multiple accept=".jpeg,.jpg,.png" required/>
                                            <div className="invalid-feedback">
                                                {invalidMessage}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <p><strong>Comentário: </strong>{props.userChallengeSubmission.submissionCommentary}</p>
                                        <p><strong>Provas de Conclusão: </strong></p>
                                        <div className="hstack gap-2">
                                            {props.userChallengeSubmission.files?.map((file) => {
                                                return (
                                                    <a key={file} href={file} target="_blank" rel="noreferrer">
                                                        <img src={file} alt="" className="thumbnail img-thumbnail" />
                                                    </a>
                                                )
                                            })}
                                        </div>
                                    </>
                                }   
                            </div>
                        </div>
                        <div className="modal-footer">
                            { submitButton ?
                                <>
                                    <button ref={modalRef} className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={InvalidateForm}>Cancelar</button>
                                    <button className="btn btn-primary text-white" type="submit">Submeter</button>
                                </>
                            :
                                <>
                                    <button className="btn btn-light" type="button" data-bs-dismiss="modal">OK</button>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}