import { useRef} from "react";
import { ModalSpinnerLoading } from "./ModalSpinnerLoading";

export const ModalAcceptSubmission: React.FC<{isLoadingModal : boolean, setForceUpdate : any, acceptSubmission : any}> = (props) => {
    const modalRef = useRef<HTMLButtonElement>(null);
    
    const handleSubmit = () => {
        props.acceptSubmission().then(() => {
            hideModal();
            props.setForceUpdate(true);
        });
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.click();
        }
    };

    return (
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-6">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    {props.isLoadingModal && <ModalSpinnerLoading/>}
                    <div className="modal-header">
                        <h4 className="modal-title text-primary">Aceitar Submissão</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body">
                        <p>Tem a certeza que quer aceitar esta submissão? Esta ação é <strong>irreversível</strong>!</p>
                    </div>
                    <div className="modal-footer">
                        <button ref={modalRef} className="btn btn-light" type="button" data-bs-dismiss="modal">Não</button>
                        <button className="btn btn-primary text-white" type="button" onClick={handleSubmit}>Sim</button>
                    </div>
                </div>
            </div>
        </div>
    )
}