import { useEffect, useState } from 'react';
import mountain from './..\\Images\\mountain.svg';

export const Mountain: React.FC<{ userProgress : any}> = (props) => {
    const [mountainName, setMountainName] = useState("Serra da Boa Viagem");
    const [landmarkName1, setLandmarkName1] = useState("Miradouro da");
    const [landmarkName2, setLandmarkName2] = useState("Bandeira");
    const [mountainAltitude, setMountainAltitude] = useState(261);
    const [currentAltitude, setCurrentAltitude] = useState(1);
    const [maxHeightSVG, setMaxHeightSVG] = useState(0);
    const [currentHeightSVG, setCurrentHeightSVG] = useState(0);

    useEffect(() => {
        switch(props.userProgress?.globalLevel){
            case 1:
                setMountainName("Serra da Boa Viagem");   
                setLandmarkName1("Miradouro da");
                setLandmarkName2("Bandeira");
                setMountainAltitude(261);
                setCurrentAltitude((261/8) * (8 - props.userProgress.numberCompletedAreas));
                break;
            case 2:
                setMountainName("Serra do Sicó");  
                setLandmarkName1("Senhora do");
                setLandmarkName2("Círculo"); 
                setMountainAltitude(406); 
                setCurrentAltitude(261 + (((406 - 261)/8) * (8 - props.userProgress.numberCompletedAreas)));
                break;
            case 3:
                setMountainName("Serra do Buçaco");   
                setLandmarkName1("Miradouro da");
                setLandmarkName2("Cruz Alta");
                setMountainAltitude(547);   
                setCurrentAltitude(406 + (((547 - 406)/8) * (8 - props.userProgress.numberCompletedAreas)));
                break;
            case 4:
                setMountainName("Serra da Lousã");  
                setLandmarkName1("Castelo do");
                setLandmarkName2("Trevim"); 
                setMountainAltitude(1205);   
                setCurrentAltitude(547 + (((1205 - 547)/8) * (8 - props.userProgress.numberCompletedAreas)));
                break;
            case 5:
                setMountainName("Serra do Açor");  
                setLandmarkName1("Monte do");
                setLandmarkName2("Colcurinho"); 
                setMountainAltitude(1244);   
                setCurrentAltitude(1205 + (((1244 - 1205)/8) * (8 - props.userProgress.numberCompletedAreas)));
                break;
            case 6:
                setMountainName("Serra do Açor");  
                setLandmarkName1("Monte do");
                setLandmarkName2("Colcurinho"); 
                setMountainAltitude(1244);   
                setCurrentAltitude(1244);
        }
    }, [props.userProgress]);

    useEffect(() =>  {
        if (window.innerWidth < 490) {
            setMaxHeightSVG(119);
        } else if (window.innerWidth < 767) {
            setMaxHeightSVG(156);
        } else {
            setMaxHeightSVG(237);
        }
        
        setCurrentHeightSVG(Math.round(maxHeightSVG - (maxHeightSVG * (currentAltitude/mountainAltitude))));
    },[currentAltitude, mountainAltitude, maxHeightSVG]);

    return (
        <div className="text-center d-flex justify-content-center mb-5 position-relative">
            <svg className="mountain-size">
                <defs>
                    <filter id="green-fill" x="0%" y="0%">
                        <feFlood floodColor="rgba(255,255,255,0.7)"/>
                        <feOffset dy={currentHeightSVG}>
                            <animate attributeName="dy" from={maxHeightSVG} to={currentHeightSVG} dur="2s"/>
                        </feOffset>
                        <feComposite operator="in" in2="SourceGraphic"/>
                        <feComposite operator="over" in2="SourceGraphic"/>
                    </filter>
                </defs>
                <image filter="url(#green-fill)" xlinkHref={mountain} className="mountain-size" />
            </svg>
            <div className="position-absolute-center">
                <span className="fs-5-custom fw-light">{mountainName}</span>
                <br/>
                <span className="display-6 fw-bold highlight">{landmarkName1}</span>
                &nbsp;
                <span className="display-6 fw-bold highlight">{landmarkName2}</span>
                <br/>
                <span className="fs-6-custom fw-bolder">{Math.round(currentAltitude)} / {mountainAltitude} m</span>
            </div>
        </div>
    )
}