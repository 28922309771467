export const ModalSpinnerLoading: React.FC<{}> = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{height:`50vh`}}>
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">
                    Loading...
                </span>
            </div>
        </div>
    )
}