import { useRef, useState } from "react";
import { ModalSpinnerLoading } from "./ModalSpinnerLoading";

export const ModalNewChallenge: React.FC<{isLoadingModal : boolean, submitChallenge : any, setForceUpdate : any}> = (props) => {
    const [field, setField] = useState(1);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isFormValidated, setIsFormValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const modalRef = useRef<HTMLButtonElement>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!formRef.current?.checkValidity()) {
            event.stopPropagation();
            setIsFormValidated(true);
        } else { 
            props.submitChallenge(field, title, description).then(() => {
                hideModal();
                if (formRef.current) {
                    formRef.current.reset();
                }
                setField(1);
                props.setForceUpdate(true);
            });
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.click();
        }
    };

    return (
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-2">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    {props.isLoadingModal ?
                        <ModalSpinnerLoading/>
                    :
                    <>
                        <form ref={formRef} onSubmit={handleSubmit} className={`needs-validation ${isFormValidated ? 'was-validated' : ''}`}  noValidate>
                            <div className="modal-header">
                                <h4 className="modal-title text-primary">Novo Desafio</h4>
                                <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <div className="vstack">
                                    <div className="form-floating mb-3">
                                        <select className="form-select" id="form-new-challenge-field" onChange={e => setField(+e.target.value)}>
                                            <option value="1">Bota</option>
                                            <option value="2">Bússola</option>
                                            <option value="3">Canivete</option>
                                            <option value="4">Constelação</option>
                                            <option value="5">Corda</option>
                                            <option value="6">Fogueira</option>
                                            <option value="7">Mosquetão</option>
                                            <option value="8">Trevo</option>
                                        </select>
                                        <label htmlFor="form-new-challenge-field">Área</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input required id="form-new-challenge-title" type="text" placeholder="Título" className="form-control" maxLength={255} onChange={e => setTitle(e.target.value)}/>
                                        <label htmlFor="form-new-challenge-title">Título</label>
                                        <div className="invalid-feedback">
                                            Por favor insira um título válido.
                                        </div>
                                    </div>
                                    <div className="form-floating">
                                        <textarea id="form-new-challenge-description" placeholder="Descrição" className="form-control h-120" maxLength={255} onChange={e => setDescription(e.target.value)}></textarea>
                                        <label htmlFor="form-new-challenge-description">Descrição</label>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-light" type="button" data-bs-dismiss="modal" ref={modalRef}>Cancelar</button>
                                <button className="btn btn-primary text-white" type="submit">Guardar</button>
                            </div>
                        </form>
                    </>
                    }
                </div>
            </div>
        </div>
    )
}