import { useEffect, useState } from "react";
import UserDetailModel from "../models/UserDetailModel";
import { Badge } from "./Badge";
import { MultipleBadge } from "./MultipleBadge";

export const BadgeWrapper: React.FC<{sectionFilter : boolean[], isSmallScreen : boolean, setSelected : any, map_position : string, badges: any, widthRatio: number, heightRatio: number, hiddenWidth: number, hiddenHeight: number}> = (props) => {

    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    const [userDetails, setUserDetails] = useState<UserDetailModel[]>([]);

    //reposition badges
    useEffect(() => {
        const mapPosition = props.map_position.split(';');

        setTop((+mapPosition[1] * props.heightRatio) - props.hiddenHeight);
        setLeft((+mapPosition[0] * props.widthRatio) - props.hiddenWidth);

    }, [props.widthRatio, props.heightRatio, props.hiddenWidth, props.hiddenHeight, props.map_position]);

    //populate badges
    useEffect(() => {
        const loadedUserDetails: UserDetailModel[] = [];
        for(const key in props.badges){
            loadedUserDetails.push({
                section: props.badges[key].section,
                clusterId: props.badges[key].clusterId,
                clusterName: props.badges[key].clusterName,
            });
        }
        setUserDetails(loadedUserDetails.filter((userDetail)=>{return props.sectionFilter[userDetail.section - 1]}));
    },[props.badges,props.sectionFilter]);

    return (
        <div className="d-flex justify-content-center align-items-center" style={{position:`absolute`, top:`${top}px`, left:`${left}px`, transformOrigin:`-50% -50%`}}>
            {userDetails.length === 0 && 
                 <div className="map-point"></div>
            }
            {userDetails.length === 1 && 
                <Badge key={`${userDetails[0].clusterId}_${userDetails[0].section}`} section={userDetails[0].section} number={userDetails[0].clusterId} name={userDetails[0].clusterName} isSmallScreen={props.isSmallScreen}/>
            }
            {userDetails.length > 1 && 
                <>
                    <MultipleBadge userDetails={userDetails} setSelected={props.setSelected} isSmallScreen={props.isSmallScreen}/>
                </>
            }
        </div>
    )
}