import { useEffect, useRef } from "react";
import UserDetailModel from "../models/UserDetailModel";

export const MultipleBadge: React.FC<{userDetails: UserDetailModel[], setSelected : any, isSmallScreen : boolean}> = (props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;
        const containerWidth = props.isSmallScreen ? 23 : 33;
        const containerHeight = props.isSmallScreen ? 23 : 33;
        const centerX = containerWidth / 2;
        const centerY = containerHeight / 2;
        const containerRadius = Math.min(centerX, centerY);
        let numBadges = props.userDetails.length;

        container.innerHTML = '';

        for (let i = 0; i < numBadges; i++) {
            const smallCircle = document.createElement('div');
            smallCircle.classList.add('small-circle');

            switch (props.userDetails[i].section){
                case 1:
                    smallCircle.classList.add('badge-lobitos');
                    break;
                case 2:
                    smallCircle.classList.add('badge-exploradores');
                    break;
                case 3:
                    smallCircle.classList.add('badge-pioneiros');
                    break;
                case 4:
                    smallCircle.classList.add('badge-caminheiros');
            }

            let circleSize = props.isSmallScreen ? 3 : 5;
            let radius = 0.6;

            /*if (numBadges > 8){
                circleSize = 3;
                radius = 0.7;
            }*/

            if (numBadges > 8) {
                numBadges = 8;
            }
        
            const angle = (i / numBadges) * 2 * Math.PI;
            const x = centerX + Math.cos(angle) * (containerRadius * radius);
            const y = centerY + Math.sin(angle) * (containerRadius * radius);
        
            smallCircle.style.left = `${x - (circleSize/2 - 0.5)}px`;
            smallCircle.style.top = `${y - (circleSize/2 - 0.5)}px`;
            smallCircle.style.width = `${circleSize}px`;
            smallCircle.style.height = `${circleSize}px`;
        
            container.appendChild(smallCircle);
        }
    }, [props.userDetails, props.isSmallScreen]);

    function handleOnClick () {
        props.setSelected(props.userDetails);
    }

    return (
        <div ref={containerRef} className="badge-multiple fw-semibold d-flex justify-content-center align-items-center text-info" onClick={handleOnClick} data-bs-target="#modal-4" data-bs-toggle="modal">
        </div>
    )
}