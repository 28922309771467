import { SectionIcon } from "./SectionIcon";
import UserDetailModel from "../models/UserDetailModel";
import { useState } from "react";

export const UserDetailRow: React.FC<{userDetail: UserDetailModel}> = (props) => {
    const [sectionTitle, setSectionTitle] = useState("");
    return ( 
        <>
            <div className="row">
                <div className="col">
                    {
                        props.userDetail.clusterId === 1 ?
                        <p className="m-0">{props.userDetail.clusterName}</p>
                        :
                        <p className="m-0"><strong>{props.userDetail.clusterId}</strong> - {props.userDetail.clusterName}</p>
                    }
                    <p className="m-0">{sectionTitle}</p>
                </div> 
                <div className="col-auto">
                    <SectionIcon section={props.userDetail.section} setTitle={setSectionTitle}/>
                </div>   
            </div>
            <hr/>
        </>
    )
}