import { useEffect, useState } from "react";

export const Landmark: React.FC<{areaWidth: string, areaHeight : string, map_position : string, mountain : string, landmark : string, height : number, side : boolean, widthRatio: number, heightRatio: number, hiddenWidth: number, hiddenHeight: number}> = (props) => {
    
    const [isVisible, setIsVisible] = useState(true);
    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);
    
    function toggleTooltip() {
        setIsVisible(!isVisible);
    }

    useEffect(() => {
        const mapPosition = props.map_position.split(';');

        setTop((+mapPosition[0] * props.heightRatio) - props.hiddenHeight);
        setLeft((+mapPosition[1] * props.widthRatio) - props.hiddenWidth);

    }, [props.widthRatio, props.heightRatio, props.hiddenWidth, props.hiddenHeight, props.map_position]);

    return (
        <div className="justify-content-center align-items-center landmark d-flex not-ready" style={{top:`${top}px`, left:`${left}px`, width:`${props.areaWidth}`, height:`${props.areaHeight}`}} onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} >
            <span className={`text-light landmark-tooltip ${isVisible ? 'd-none' : ''} ${props.side ? 'landmark-right' : 'landmark-left'}`}>
                <strong><span>&nbsp;{props.mountain}&nbsp;</span></strong><br/>
                <span >&nbsp;{props.landmark}&nbsp;</span><br/>
                <em><span>&nbsp;{props.height} m&nbsp;</span></em>
            </span>
        </div>
    )
}