export const FAQ: React.FC<{}> = (props) => {
    return (
        <section className="gradient-bg">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="text-center d-flex flex-column justify-content-center align-items-center mt-5pct mb-5">
                            <p id="intro" className="mt-10pct">
                            O grande objetivo da dinâmica dos Cincos Picos é que cada <strong>secção</strong> de cada Agrupamento complete <strong>desafios</strong> relacionados com o método escutista para assim conseguir atingir os Cinco Picos da Região de Coimbra escolhidos para este jogo.<br/><br/>
                            Os desafios estão divididos em várias áreas que são representadas por objetos: <strong>botas, trevo de quatro folhas, mosquetão, bússola, constelação, canivete, fogueira e corda.</strong><br/><br/>
                            Para subir de nível e conseguir avançar no jogo é necessário completar o número de desafios indicado abaixo para cada área:<br/><br/>
                            Nível 1 - <i>Miradouro da Bandeira</i> - 1 desafio por área<br/>
                            Nível 2 - <i>Senhora do Círculo</i> - 2 desafios por área<br/>
                            Nível 3 - <i>Cruz Alta</i> - 2 desafios por área<br/>
                            Nível 4 - <i>Trevim</i> - 2 desafios por área<br/>
                            Nível 5 - <i>Monte do Colcurinho</i> - 3 desafios por área<br/><br/>
                            Na área de jogo de cada utilizador podem encontrar todos os desafios disponíveis para cada secção, sendo que esta lista vai sendo atualizada ao longo do tempo. A escolha dos desafios a cumprir é feita pela secção, sendo que os mesmos apenas podem ser cumpridos uma vez.<br/><br/>
                            Para validar o cumprimento dos desafios, a secção deve submeter 3 fotos e uma breve descrição da forma que utilizaram/o que fizeram para os cumprir. Após validação dos mesmos, por parte da equipa da Ponte Himalaias, o ícone da secção começa a subir rumo ao topo do pico que se propõe atingir!
                            </p>
                            <div className="display-4 mt-5 mb-4 w-100">
                                <span className="fw-bold highlight text-white">Perguntas</span>
                                &nbsp;
                                <span className="fw-bold highlight text-white">Frequentes</span>
                            </div>
                        </div>
                    </div>
                    <div className="accordion accordion-desafios mb-3" role="tablist" id={`accordion-faq`}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-1`} aria-expanded="false" aria-controls={`#accordion-faq .item-1`}>
                                    <strong className="text-primary mr-3">Há algum prémio para o vencedor?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                        O prémio que ganhas se a tua secção completar o jogo e conseguir atingir os 5 Picos é uma estadia grátis (1 noite) num dos campos escutistas da Região de Coimbra.
                                    </p>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-2`} aria-expanded="false" aria-controls={`#accordion-faq .item-2`}>
                                    <strong className="text-primary mr-3">Estou a ter dificuldades no jogo, o que faço?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-2" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                    Podes contactar-nos através do email <a href="mailto:5picos.coimbra@escutismo.pt">5picos.coimbra@escutismo.pt</a><br/><br/>
                                    Somos rápidos a responder!
                                    </p>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-3`} aria-expanded="false" aria-controls={`#accordion-faq .item-3`}>
                                    <strong className="text-primary mr-3">Não sou da Região de Coimbra… Posso participar?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-3" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                    Este jogo foi desenvolvido exclusivamente para Agrupamentos da Região de Coimbra.<br/><br/>
                                    Desafia a Equipa Pedagógica da tua Região a fazer o mesmo!
                                    </p>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-4`} aria-expanded="false" aria-controls={`#accordion-faq .item-4`}>
                                    <strong className="text-primary mr-3">Posso estar sempre a fazer desafios?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-4" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                    Sim! Os desafios devem fazer parte da vida da tua secção e devem decidir em conjunto a melhor altura para os realizar.<br/><br/>
                                    Podes até fazer vários desafios da mesma área ao mesmo tempo mas, não te esqueças que para atingir o pico <u>tens de cumprir o número mínimo de desafios indicado para cada área.</u>
                                    </p>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-5`} aria-expanded="false" aria-controls={`#accordion-faq .item-5`}>
                                    <strong className="text-primary mr-3">O meu desafio não foi aprovado! O que faço?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-5" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                    Se o teu desafio não for aprovado, vais encontrar na tua área de utilizador o motivo pelo qual foi rejeitado. Podes sempre esforçar-te um pouco mais e submeter novamente.<br/><br/>
                                    No momento da submissão das fotos, lembra-te que tens de conseguir explicar a alguém que não esteve na tua atividade o que fizeste. Por isso escolhe bem as tuas fotos e escreve uma descrição que mostre realmente o que foi o vosso trabalho!
                                    </p>  
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" role="tab">
                                <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-faq .item-6`} aria-expanded="false" aria-controls={`#accordion-faq .item-6`}>
                                    <strong className="text-primary mr-3">Como é o jogo passo a passo?</strong>
                                </button>
                            </h2>
                            <div className="accordion-collapse collapse item-6" role="tabpanel" data-bs-parent={`#accordion-faq`}>
                                <div className="accordion-body">
                                    <p>
                                        <strong>1.</strong>  Ler as Instruções do jogo e tirar as dúvidas que surgirem.<br/>
                                        <strong>2.</strong>  Fazer o primeiro acesso à área de utilizador e alterar a palavra-passe.<br/>
                                        <strong>3.</strong>  Em secção, discutir os desafios a que se querem propor.<br/>
                                        <strong>4.</strong>  Realizar os desafios.<br/>
                                        <strong>5.</strong>  Submeter no site 3 fotos e uma descrição do que fizeram.<br/>
                                        <strong>6.</strong>  Esperar a aprovação de cada desafio.<br/>
                                        <strong>7.</strong>  Continuar a fazer desafios 😎
                                    </p>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}