import { ModalChallenge } from './ModalChallenge';
import { ModalNewChallenge } from './ModalNewChallenge';
import { Mountain } from './Mountain';
import { Area } from './Area';
import { useEffect, useState } from 'react';
import { SpinnerLoading } from './Utils/SpinnerLoading';
import { useOktaAuth } from '@okta/okta-react';
import UserChallengeSubmissionModel from '../models/UserChallengeSubmissionModel';
import { ErrorOverlay } from './Utils/ErrorOverlay';
import ChallengeRequestModel from '../models/ChallengeRequestModel';

import AdminChallengeDetailModel from '../models/AdminChallengeDetailModel';
import { ModalChallengeDetail } from './ModalChallengeDetail';
import SubmissionModel from '../models/SubmissionModel';

export const Challenges = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [httpError, setHttpError] = useState(null);
    const [response, setResponse] = useState<any>([]);
    const [challengeId, setChallengeId] = useState<number>();
    const [adminChallengeId, setAdminChallengeId] = useState(null);
    const [userChallengeSubmission, setUserChallengeSubmission] = useState<any>([]);
    const [adminChallengeDetail, setAdminChallengeDetail] = useState<any>([]);
    const [userProgress, setUserProgress] = useState<any>(null);
    const [sectionFilter, setSectionFilter] = useState(1);
    const [forceUpdate, setForceUpdate] = useState(false);

    const {authState} = useOktaAuth();
    
    //get challenges
    useEffect(() => {
        const fetchUserChallenges = async () => {
            setForceUpdate(false);
            setIsLoading(true);
            if (!authState || !authState.isAuthenticated){
                setIsLoading(false);
                return;
            }

            let url = `https://5picos.coimbra.escutismo.pt/api/challenges/secure`;
            if (authState?.accessToken?.claims.userType === "admin") {
                url += "/admin/section/" + sectionFilter;
            } else {
                url += "/section";
            }
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();
            setResponse(responseJson);
            setIsLoading(false);
        };
        fetchUserChallenges().catch((error:any) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    }, [authState,sectionFilter,forceUpdate]);

    //get user progress
    useEffect(() => {
        const fetchUserProgress = async () => {
            setIsLoading(true);
            if (!authState || !authState.isAuthenticated || authState?.accessToken?.claims.userType === "admin"){
                setIsLoading(false);
                return;
            }
            const url = `https://5picos.coimbra.escutismo.pt/api/challenges/secure/progress`;
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();
            setUserProgress(responseJson);
            setIsLoading(false);
        };
        fetchUserProgress().catch((error:any) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    }, [authState]);

    //get clicked challenge's info
    useEffect(() => {
        const fetchUserChallengeSubmission = async () => {
            setIsLoadingModal(true);
            if (!authState || !authState.isAuthenticated || !challengeId || challengeId === -1){
                setIsLoadingModal(false);
                return;
            }

            const url = `https://5picos.coimbra.escutismo.pt/api/challenges/secure/challenge/${challengeId}`;
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();

            const loadedUserChallengeSubmission: UserChallengeSubmissionModel = {
                challengeId: responseJson.challenge.challengeId,
                challengeTitle: responseJson.challenge.title,
                challengeDescription: responseJson.challenge.description,
                challengeField: responseJson.challenge.field,
                submissionId: responseJson.submission?.submissionId,
                submissionCommentary: responseJson.submission?.commentary,
                submissionFeedback: responseJson.submission?.feedback,
                stateId: responseJson.state?.stateId,
                stateLabel: responseJson.state?.label,
                files: []
            };
            
            if(responseJson.fileIds != null){
                const tempFiles = [];
                for (let i = 0; i < responseJson.fileIds.length; i++) {
                    const url = `https://5picos.coimbra.escutismo.pt/api/files/secure/download/${responseJson.fileIds[i]}`;
                    const requestOptions = {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                        }
                    };
                    const response = await fetch(url, requestOptions);
                    if (!response.ok){
                        throw new Error("Não foi possível descarregar o ficheiro.");
                    }else{
                        const responseBody = await response.blob(); 
                        const responseBodyUrl = URL.createObjectURL(responseBody);
                        tempFiles.push(responseBodyUrl);        
                    }
                }
                loadedUserChallengeSubmission.files = tempFiles;
            }

            setUserChallengeSubmission(loadedUserChallengeSubmission);

            setIsLoadingModal(false);
        };
        fetchUserChallengeSubmission().catch((error:any) => {
            setIsLoadingModal(false);
            setHttpError(error.message);
        });
    }, [challengeId, authState]);

    //get clicked admin challenge's info
    useEffect(() => {
        const fetchAdminChallengeDetail = async () => {
            setIsLoadingModal(true);
            if (!authState || !authState.isAuthenticated || !adminChallengeId || authState?.accessToken?.claims.userType !== "admin"){
                setIsLoadingModal(false);
                return;
            }

            const url = `https://5picos.coimbra.escutismo.pt/api/challenges/secure/admin/challenge/${adminChallengeId}`;
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();

            const loadedAdminChallengeDetail: AdminChallengeDetailModel = {
                challengeId: responseJson.challengeId,
                challengeTitle: responseJson.title,
                challengeDescription: responseJson.description,
                challengeField: responseJson.field,
                challengeSection: responseJson.section,
            };

            setAdminChallengeDetail(loadedAdminChallengeDetail);
            
            setIsLoadingModal(false);
        };
        fetchAdminChallengeDetail().catch((error:any) => {
            setIsLoadingModal(false);
            setHttpError(error.message);
        });
    }, [adminChallengeId, authState]);

    //add new challenge
    const submitChallenge = async (field: number, title: string, description: string) => {
        try {
            setIsLoading(true);
            if (!authState || !authState.isAuthenticated || authState?.accessToken?.claims.userType !== "admin"){
                return;
            }

            const challengeRequestModel = new ChallengeRequestModel(field, sectionFilter, title, description);
            const url = `https://5picos.coimbra.escutismo.pt/api/challenges/secure/admin/challenge`;
            const requestOptions = {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(challengeRequestModel)
            };
    
            const submitChallengeResponse = await fetch(url, requestOptions);
            if (!submitChallengeResponse.ok) 
                throw new Error("Não foi possível criar o novo desafio");

            setIsLoading(false);
        } catch (error:any) {
            setIsLoading(false);
            setHttpError(error.message);
        }
    };

    //create a submission
    const submitSubmission = async (challengeId: number, submissionId: number, commentary: string, files: any[]) => {
        try {
            if (!authState || !authState.isAuthenticated){
                return;
            }
            setIsLoadingModal(true);
            await uploadFile(files).then(async (fileIds) => {
                const submissionModel = new SubmissionModel(challengeId, submissionId, commentary, fileIds);
                await associateFilesToSubmission(submissionModel).then(() => setChallengeId(-1));
            });
            setIsLoadingModal(false);
        } catch (error:any) {
            setIsLoadingModal(false);
            setHttpError(error.message);
        }
    };

    //upload a file to the server
    const uploadFile = async (files: File[]) => {
        const newFiles = files;
        let fileIds = [];
        for (let i = 0; i < newFiles.length; i++) {
            const formData = new FormData();
            formData.append(`file`, newFiles[i]);
            const url = `https://5picos.coimbra.escutismo.pt/api/files/secure/upload`;
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                },
                body: formData
            };
            const response = await fetch(url, requestOptions);
            const responseBody = await response.text(); 
            if (!response.ok){
                throw new Error("Não foi possível carregar o ficheiro.");
            }else{
                if(responseBody){
                    fileIds.push(+responseBody);
                }  
                
            }
        }
        return fileIds; 
    }

    //associate each uploaded file to a submission
    const associateFilesToSubmission = async (submission : SubmissionModel) => {
        const url = `https://5picos.coimbra.escutismo.pt/api/submissions/secure/submission`;
        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
                'Content-Type': "application/json"
            },
            body: JSON.stringify(submission)
        };

        const response = await fetch(url, requestOptions);
        if (!response.ok){
            throw new Error("Não foi possível criar a submissão.");
        }
    }

    if (httpError) {
        return (
            <ErrorOverlay httpError={httpError}/>
        );
    }
    
    return (
        <>
            {isLoading &&  <SpinnerLoading/>}
            <div>
                <ModalNewChallenge isLoadingModal={isLoadingModal} submitChallenge={submitChallenge} setForceUpdate={setForceUpdate}/>
                <ModalChallenge isLoadingModal={isLoadingModal} userChallengeSubmission={userChallengeSubmission} submitSubmission={submitSubmission} forceUpdateChallenges={setForceUpdate}/>
                <ModalChallengeDetail isLoadingModal={isLoadingModal} adminChallengeDetail={adminChallengeDetail}/>
                <section className="d-flex justify-content-center align-items-center">
                    <div className="container mb-5 mt-150">
                        { authState?.accessToken?.claims.userType !== "admin" ?
                            <>
                                <Mountain userProgress={userProgress} />
                                <Area area={1} challenges={response['1']?.userChallenges} level={response['1']?.level} setChallengeId={setChallengeId}/>
                                <Area area={2} challenges={response['2']?.userChallenges} level={response['2']?.level} setChallengeId={setChallengeId}/>
                                <Area area={3} challenges={response['3']?.userChallenges} level={response['3']?.level} setChallengeId={setChallengeId}/>
                                <Area area={4} challenges={response['4']?.userChallenges} level={response['4']?.level} setChallengeId={setChallengeId}/>
                                <Area area={5} challenges={response['5']?.userChallenges} level={response['5']?.level} setChallengeId={setChallengeId}/>
                                <Area area={6} challenges={response['6']?.userChallenges} level={response['6']?.level} setChallengeId={setChallengeId}/>
                                <Area area={7} challenges={response['7']?.userChallenges} level={response['7']?.level} setChallengeId={setChallengeId}/>
                                <Area area={8} challenges={response['8']?.userChallenges} level={response['8']?.level} setChallengeId={setChallengeId}/>
                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="col d-flex align-items-center">
                                        <button className="btn btn-white border-button mb-4" data-bs-target="#modal-2" data-bs-toggle="modal" type="button">Novo Desafio</button>
                                    </div>
                                    <div className="col-auto">
                                        <select className="form-select mb-4 border-button" id="section-filter" onChange={e => setSectionFilter(+e.target.value)}>
                                            <option value="1">Lobitos</option>
                                            <option value="2">Exploradores</option>
                                            <option value="3">Pioneiros</option>
                                            <option value="4">Caminheiros</option>
                                        </select>
                                    </div>
                                </div>
                                <Area area={1} challenges={response['1']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={2} challenges={response['2']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={3} challenges={response['3']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={4} challenges={response['4']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={5} challenges={response['5']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={6} challenges={response['6']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={7} challenges={response['7']} level={0} setChallengeId={setAdminChallengeId}/>
                                <Area area={8} challenges={response['8']} level={0} setChallengeId={setAdminChallengeId}/>
                            </>
                        }
                    </div>
                </section>
            </div>
        </>
    );
} 