
import { useEffect, useState } from "react";

import lobitos from './..\\Images\\Sections\\lobitos.svg';
import exploradores from './..\\Images\\Sections\\exploradores.svg';
import pioneiros from './..\\Images\\Sections\\pioneiros.svg';
import caminheiros from './..\\Images\\Sections\\caminheiros.svg';

export const SectionIcon: React.FC<{ section : number, setTitle : any }> = (props) => {
    const [icon, setIcon] = useState<string>();
    const [title, setTitle] = useState<string>();

    useEffect(() => {
        switch(props.section){
            case 1:
                setIcon(lobitos);    
                if(props.setTitle) props.setTitle("Lobitos");
                setTitle("Lobitos");
                break;
            case 2:
                setIcon(exploradores);   
                if(props.setTitle) props.setTitle("Exploradores/Moços");
                setTitle("Exploradores/Moços");
                break;
            case 3:
                setIcon(pioneiros);    
                if(props.setTitle) props.setTitle("Pioneiros/Marinheiros");
                setTitle("Pioneiros/Marinheiros");
                break;
            case 4:
                setIcon(caminheiros);    
                if(props.setTitle) props.setTitle("Caminheiros/Companheiros");
                setTitle("Caminheiros/Companheiros");
                break;
        }
    }, [props]);
    
    return (
        <img src={icon} alt={title} className="mr-10" width="48" height="44"/>               
    )
}