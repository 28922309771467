import { useEffect, useState } from "react";
import UserChallengesModel from "../models/UserChallengesModel";
import { Tooltip } from 'react-tooltip'

export const Challenge : React.FC<{key : number, userChallenge : UserChallengesModel, setChallengeId : any}> = (props) => {
    const [stateClassName, setStateClassName] = useState("challenge-open");
    const [stateSVG, setStateSVG] = useState(
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-circle fs-5 text-white">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                </svg>
            );

    useEffect(() => {
        switch (props.userChallenge.stateId){
            case 1: 
                setStateClassName("challenge-open");
                setStateSVG(
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-circle fs-5 text-white">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    </svg>
                );
                break;
            case 2: 
                setStateClassName("challenge-submitted");
                setStateSVG(
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-clock-fill fs-5 text-white" data-bs-toggle="tooltip" data-bss-tooltip="">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                    </svg>
                );
                break;
            case 3: 
                setStateClassName("challenge-accepted");
                setStateSVG(
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-check-circle-fill fs-5 text-white" data-bs-toggle="tooltip" data-bss-tooltip="">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                    </svg>
                );
                break;
            case 4: 
                setStateClassName("challenge-rejected");
                setStateSVG(
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-exclamation-circle-fill fs-5 text-white" data-bs-toggle="tooltip" data-bss-tooltip="">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                    </svg>
                );
        }
    },[props.userChallenge.stateId]);

    function onClickHandler() {
        props.setChallengeId(props.userChallenge.challengeId);
    }

    return (
        <>
            <a onClick={onClickHandler} className="text-decoration-none" href="desafios.html" data-bs-target="#modal-1" data-bs-toggle="modal">
                <div>
                    <div className={`row challenge ${stateClassName}`}>
                        <div className="col">
                            <p className="m-0">
                                {props.userChallenge.challengeTitle}
                            </p>
                        </div>
                        <div data-tooltip-id={`status-tooltip-`+props.userChallenge.challengeId} data-tooltip-content={props.userChallenge.stateLabel} data-tooltip-place="bottom" className="col-auto d-flex align-items-center">
                            {stateSVG}
                            <Tooltip id={`status-tooltip-`+props.userChallenge.challengeId} />
                        </div>
                    </div>
                </div>
            </a>
        </>
    )
}