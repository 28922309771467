class SubmissionModel {
    challengeId: number;
    submissionId?: number;
    commentary?: string;
    files: number[];

    constructor(challengeId: number, submissionId : number, commentary : string, files : number[]) {
        this.challengeId = challengeId;
        this.submissionId = submissionId;
        this.commentary = commentary;
        this.files = files;
    }
}

export default SubmissionModel;