import { AreaIcon } from './AreaIcon';
import { SectionIcon } from './SectionIcon';
import SubmissionDetail from '../models/SubmissionDetail';
import { useState } from 'react';
import { ErrorOverlay } from './Utils/ErrorOverlay';
import { useOktaAuth } from '@okta/okta-react';

export const Submission : React.FC<{submission : SubmissionDetail, setSubmissionId : any}> = (props) => {

    const [httpError, setHttpError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [imageURLs, setImageURLs] = useState<string[]>();

    const {authState} = useOktaAuth();

    const fecthSubmissionFiles = async () => {
        setIsLoading(true);
        if (!authState || !authState.isAuthenticated){
            setIsLoading(false);
            return;
        }
        const tempFiles = [];
        for (let i = 0; i < props.submission.fileIds.length; i++) {
            const url = `https://5picos.coimbra.escutismo.pt/api/files/secure/download/${props.submission.fileIds[i]}`;
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                }
            };
            const response = await fetch(url, requestOptions);
            if (!response.ok){
                throw new Error("Não foi possível descarregar o ficheiro.");
            }else{
                const responseBody = await response.blob(); 
                const responseBodyUrl = URL.createObjectURL(responseBody);
                tempFiles.push(responseBodyUrl);        
            }
        }
        setImageURLs(tempFiles);
        setIsLoading(false);
    }

    const handleClick = () => {
        setTimeoutId(
            setTimeout(() => {
                if (imageURLs === undefined){
                    fecthSubmissionFiles().catch((error:any) => {
                        setIsLoading(false);
                        setHttpError(error.message);
                    });
                } 
            }, 500)
        );
        return () => clearTimeout(timeoutId);
    }

    const handleButtonClick = () => {
        props.setSubmissionId(props.submission.submissionId);
    }

    if (httpError) {
        return (
            <ErrorOverlay httpError={httpError}/>
        );
    }

    return (
        <div className="accordion accordion-desafios mb-3" role="tablist" id={`accordion-${props.submission.submissionId}`}>
            <div className="accordion-item">
                <h2 className="accordion-header" role="tab">
                    <button className="accordion-button collapsed position-relative" onClick={handleClick} type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${props.submission.submissionId} .item-1`} aria-expanded="false" aria-controls={`#accordion-${props.submission.submissionId} .item-1`}>
                        <SectionIcon section={props.submission.challengeSection} setTitle={undefined} />
                        <strong className="text-primary mr-3">{props.submission.clusterDescriptor}</strong>
                        <div className="position-absolute right-5">
                            <AreaIcon area={props.submission.challengeField} setTitle={undefined} />
                        </div>
                    </button>
                </h2>
                <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent={`#accordion-${props.submission.submissionId}`}>
                    <div className="accordion-body">
                        <div>
                            <div className="vstack">
                                <p className='mt-2'><strong>{props.submission.challengeTitle} - </strong>{props.submission.challengeDescription}</p>
                                <p className='mt-2'><span style={{textDecoration:'underline'}}>Nota do Utilizador</span>:&nbsp;{props.submission.submissionComment}</p>
                                <div className="mt-3 row">
                                    <div className="col">
                                        {isLoading ?
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">
                                                    Loading...
                                                </span>
                                            </div>
                                            :
                                            <div className="hstack gap-2">
                                                {imageURLs?.map((file) => {
                                                    return (
                                                        <a key={file} href={file} target="_blank" rel="noreferrer">
                                                            <img src={file} alt="" className="thumbnail img-thumbnail" />
                                                        </a>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-3 d-flex flex-row-reverse">
                                    <button className="btn btn-warning text-white" type="button" onClick={handleButtonClick} data-bs-target="#modal-5" data-bs-toggle="modal">
                                        Rejeitar
                                    </button>
                                    <button className="btn btn-success me-3 text-white" onClick={handleButtonClick} type="button" data-bs-target="#modal-6" data-bs-toggle="modal">Aceitar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}