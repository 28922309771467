import { useRef, useState } from "react";
import { ModalSpinnerLoading } from "./ModalSpinnerLoading";

export const ModalRejectSubmission: React.FC<{isLoadingModal : boolean, setForceUpdate : any, rejectSubmission : any}> = (props) => {
    const [feedback, setFeedback] = useState('');
    const [isFormValidated, setIsFormValidated] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);
    const modalRef = useRef<HTMLButtonElement>(null);
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!formRef.current?.checkValidity()) {
            event.stopPropagation();
            setIsFormValidated(true);
        } else { 
            props.rejectSubmission(feedback).then(() => {
                hideModal();
                if (formRef.current) {
                    formRef.current.reset();
                }
                props.setForceUpdate(true);
            });
        }
    };

    const hideModal = () => {
        if (modalRef.current) {
            modalRef.current.click();
        }
    };

    return (
        <div className="modal fade" role="dialog" tabIndex={-1} id="modal-5">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    {props.isLoadingModal && <ModalSpinnerLoading/>}
                    <form ref={formRef} onSubmit={handleSubmit} className={`needs-validation ${isFormValidated ? 'was-validated' : ''} ${props.isLoadingModal && `d-none`}`}  noValidate>
                        <div className="modal-header">
                            <h4 className="modal-title text-primary">Rejeitar Submissão</h4>
                            <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <div className="vstack">
                                <div className="form-floating mb-3">
                                    <textarea id="form-reject-submission-feedback" placeholder="Feedback para o Utilizador" className="form-control h-120" maxLength={255} required onChange={e => setFeedback(e.target.value)}></textarea>
                                    <label htmlFor="form-new-challenge-description">Feedback</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button ref={modalRef} className="btn btn-light" type="button" data-bs-dismiss="modal">Cancelar</button>
                            <button className="btn btn-primary text-white" type="submit">Enviar Feedback</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}