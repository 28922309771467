class ChallengeRequestModel{
    field: number;
    section: number;
    title: string;
    description?: string;

    constructor(field: number, section: number, title: string, description: string){
        this.field = field;
        this.section = section;
        this.title = title;
        this.description = description;
    }
}

export default ChallengeRequestModel;