import { Link, NavLink } from "react-router-dom"
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { useOktaAuth } from "@okta/okta-react";
import new_logo_white from './..\\..\\Images\\logo_bw_abrev.svg'
import { useEffect, useState } from "react";

export const Navbar = () => {

    const {oktaAuth, authState} = useOktaAuth();
    const [opacity, setOpacity] = useState(0);
    const [displayName, setDisplayName] = useState('');
    const [usernameBtnText, setUsernameBtnText] = useState('');
    const [btnWidth, setBtnWidth] = useState(8);

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          const newOpacity = 0 + scrollPosition / 500;
          setOpacity(newOpacity < 1 ? newOpacity : 1);
        };

        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const username = authState?.accessToken?.claims.sub; 
        if (username) {
            switch(username.substring(0,3)) {
                case 'lob':
                    setDisplayName("Lobitos " + username?.substring(3));
                    break;
                case 'exp':
                    setDisplayName("Exploradores " + username?.substring(3));
                    break;
                case 'moc':
                    setDisplayName("Moços " + username?.substring(3));
                    break;
                case 'pio':
                    setDisplayName("Pioneiros " + username?.substring(3));
                    break;
                case 'mar':
                    setDisplayName("Marinheiros " + username?.substring(3));
                    break;
                case 'cam':
                    setDisplayName("Caminheiros " + username?.substring(3));
                    break;
                case 'com':
                    setDisplayName("Companheiros " + username?.substring(3));
                    break;
                case 'cuc':
                    setDisplayName("CUC");
                    break;
                default:
                    setDisplayName(username);
            }
        }
    }, [authState?.accessToken?.claims.sub]);

    useEffect(() => {
        setUsernameBtnText(displayName);
        const nameSize = displayName.length; 
        setBtnWidth(nameSize < 10 ? 7 : nameSize - 3);
    }, [displayName]);

    if(!authState)
        return <SpinnerLoading/>

    const handleLogout = async () => oktaAuth.signOut();

    const handleMouseEnter = () => {
        setUsernameBtnText('Sair');
    }

    const handleMouseExit = () => {
        setUsernameBtnText(displayName);
    }

    return (
        <nav id="navbar" className="navbar navbar-expand-md fixed-top py-3" style={{backgroundColor: `rgba(173, 203, 2, ${opacity})`, transition: 'opacity 0.3s ease-in-out'}}>
            <div className="container-fluid">
                <Link className="navbar-brand d-flex align-items-end" to="/home">
                    <img id="logo-navbar" alt="logo jrc" src={new_logo_white}/>
                </Link>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-2">
                    <span className="visually-hidden">Toggle navigation</span>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-2">
                    <ul className="navbar-nav ms-auto align-items-end">
                        { authState?.accessToken?.claims.userType !== "admin" &&
                            <li className="nav-item">
                                <NavLink className="nav-link nav-link-white" to='/faq'>Como Funciona?</NavLink>
                            </li>
                        }
                        { authState.isAuthenticated &&
                            <li className='nav-item'>
                                <NavLink className='nav-link nav-link-white' to='/challenges'>Desafios</NavLink>
                            </li>
                        }
                        { authState?.accessToken?.claims.userType === "admin" &&
                            <li className='nav-item'>
                                <NavLink className='nav-link nav-link-white' to='/submissions'>Submissões</NavLink>
                            </li>
                        }
                        { !authState.isAuthenticated ?
                            <li className='nav-item m-1'>
                                <Link className="btn btn-white ms-2 border-button" role="button" to='/login'>Entrar</Link>
                            </li>
                            :
                            <li className="nav-item">
                                <button className="btn btn-white ms-2 border-button nav-username-button" style={{width:`${btnWidth}rem`}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit} onClick={handleLogout}>{usernameBtnText}</button>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </nav>
    )
}