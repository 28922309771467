import mapa from './..\\Images\\map_picos.png';
import lobitos from './..\\Images\\Sections\\lobitos.svg';
import exploradores from './..\\Images\\Sections\\exploradores.svg';
import pioneiros from './..\\Images\\Sections\\pioneiros.svg';
import caminheiros from './..\\Images\\Sections\\caminheiros.svg';

import { ButtonSection } from './ButtonSection';
import { Landmark } from './Landmark';
import { useEffect, useRef, useState } from 'react';
import { BadgeWrapper } from './BadgeWrapper';
import { SpinnerLoading } from './Utils/SpinnerLoading';
import { ErrorOverlay } from './Utils/ErrorOverlay';
import { ModalUsers } from './ModalUsers';
import UserDetailModel from '../models/UserDetailModel';

export const MapPicos = () => {
    const imageRef = useRef<HTMLImageElement>(null);
    const [widthRatio, setWidthRatio] = useState(0);
    const [heightRatio, setHeightRatio] = useState(0);
    const [hiddenWidth, setHiddenWidth] = useState(0);
    const [hiddenHeight, setHiddenHeight] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [response, setResponse] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [httpError, setHttpError] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [selectedUserDetails, setSelectedUserDetails] = useState<UserDetailModel[]>([]);
    const [sectionFilter, setSectionFilter] = useState([true,true,true,true]);

    //calculate new map dimensions on resize
    useEffect(() => {
        function handleResize() {
            if (imageRef.current) {
                const imageRect = imageRef.current.getBoundingClientRect();
                const visibleHeight = (imageRect.width * imageRef.current.naturalHeight) / imageRef.current.naturalWidth;
                setWidthRatio(imageRect.width / imageRef.current.naturalWidth);
                setHeightRatio(visibleHeight / imageRef.current.naturalHeight);
                setHiddenWidth((imageRect.width - window.innerWidth) / 2);
                setHiddenHeight((visibleHeight - window.innerHeight) / 2);
                setIsSmallScreen(window.innerWidth < 767 ? true : false);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [imageLoaded]);

    //get all users progress
    useEffect(() => {
        const fetchUserChallenges = async () => {
            let url = `https://5picos.coimbra.escutismo.pt/api/submissions/user/progress`;
            
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': "application/json"
                }
            };

            const responseFetch = await fetch(url, requestOptions);
            if (!responseFetch.ok) {
                throw new Error('Something went wrong!');
            }
            const responseJson = await responseFetch.json();
            setResponse(responseJson);
        };
        fetchUserChallenges().catch((error:any) => {
            setIsLoading(false);
            setHttpError(error.message);
        });
    },[]);

    function onImageLoad() {
        setImageLoaded(true);
        setIsLoading(false);
    }

    function onFilterSection(section : number, value : boolean){
        let items = [...sectionFilter];
        items[section - 1] = value;
        setSectionFilter(items);
    }

    if (httpError) {
        return (
            <ErrorOverlay httpError={httpError}/>
        );
    }
    
    return (
        <>
            {isLoading && <SpinnerLoading/>}
            <section>
                <ModalUsers userDetails={selectedUserDetails}/>
                <div className="pos-rel">
                    <div className="container d-flex justify-content-center" id="container-map">
                        <img ref={imageRef} className="d-xl-flex flex-shrink-0" id="map" alt="" src={mapa} onLoad={onImageLoad}/>
                        <Landmark areaWidth={'30pt'} areaHeight={'25pt'} map_position={'1750;2480'} mountain={'Serra da Boa Viagem'} landmark={'Miradouro da Bandeira'} height={261} side={true} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <Landmark areaWidth={'25pt'} areaHeight={'25pt'} map_position={'1430;1905'} mountain={'Serra do Sicó'} landmark={'Senhora do Círculo'} height={406} side={false} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <Landmark areaWidth={'25pt'} areaHeight={'35pt'} map_position={'1270;2425'} mountain={'Serra do Buçaco'} landmark={'Cruz Alta'} height={547} side={true} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <Landmark areaWidth={'30pt'} areaHeight={'50pt'} map_position={'1000;1920'} mountain={'Serra da Lousã'} landmark={'Trevim'} height={1205} side={false} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <Landmark areaWidth={'40pt'} areaHeight={'25pt'} map_position={'910;2400'} mountain={'Serra do Açor'} landmark={'Monte do Colcurinho'} height={1244} side={true} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>

                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2830;2155'} badges={response['0']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2735;2130'} badges={response['1']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2640;2110'} badges={response['2']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2545;2090'} badges={response['3']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2450;2075'} badges={response['4']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2355;2055'} badges={response['5']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2354;1957'} badges={response['6']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2434;1889'} badges={response['7']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2430;1790'} badges={response['8']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2330;1805'} badges={response['9']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2222;1815'} badges={response['10']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2110;1822'} badges={response['11']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2000;1825'} badges={response['12']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1889;1820'} badges={response['13']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1805;1772'} badges={response['14']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1849;1680'} badges={response['15']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1912;1600'} badges={response['16']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1977;1525'} badges={response['17']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2074;1520'} badges={response['18']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2165;1512'} badges={response['19']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2260;1500'} badges={response['20']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2355;1485'} badges={response['21']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2454;1460'} badges={response['22']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2520;1390'} badges={response['23']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2400;1315'} badges={response['24']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2315;1288'} badges={response['25']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2225;1265'} badges={response['26']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2135;1254'} badges={response['27']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2045;1255'} badges={response['28']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1955;1280'} badges={response['29']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'1990;1185'} badges={response['30']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2035;1105'} badges={response['31']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2086;1032'} badges={response['32']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2180;1075'} badges={response['33']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2270;1115'} badges={response['34']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2360;1150'} badges={response['35']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2455;1180'} badges={response['36']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2550;1200'} badges={response['37']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2645;1220'} badges={response['38']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2590;1115'} badges={response['39']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                        <BadgeWrapper sectionFilter={sectionFilter} isSmallScreen={isSmallScreen} setSelected={setSelectedUserDetails} map_position={'2550;1030'} badges={response['40']} widthRatio={widthRatio} heightRatio={heightRatio} hiddenWidth={hiddenWidth} hiddenHeight={hiddenHeight}/>
                    </div>
                    <div className="section-button-group">
                        <div className="full-opacity" role="group" id="btn-group-seccoes">
                            <ButtonSection setSectionFilter={onFilterSection} section={1} img={lobitos} sectionName='lobitos' className="btn-group-start"/>
                            <ButtonSection setSectionFilter={onFilterSection} section={2} img={exploradores} sectionName='exploradores' className="btn-group-middle"/>
                            <ButtonSection setSectionFilter={onFilterSection} section={3} img={pioneiros} sectionName='pioneiros' className="btn-group-middle"/>
                            <ButtonSection setSectionFilter={onFilterSection} section={4} img={caminheiros} sectionName='caminheiros' className="btn-group-end"/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
} 