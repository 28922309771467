import { Tooltip } from 'react-tooltip'
import AdminChallengesModel from "../models/AdminChallengesModel";

export const AdminChallenge : React.FC<{key : number, adminChallenge : AdminChallengesModel, setChallengeId : any}> = (props) => {

    function onClickHandler() {
        props.setChallengeId(props.adminChallenge.challengeId);
    }

    return (
        <>
            <a onClick={onClickHandler} className="text-decoration-none" href="desafios.html" data-bs-target="#modal-3" data-bs-toggle="modal">
                <div>
                    <div className="row challenge challenge-open">
                        <div className="col">
                            <p className="m-0">
                                {props.adminChallenge.challengeTitle}
                            </p>
                        </div>
                        <div data-tooltip-id={`submitted-tooltip-`+props.adminChallenge.challengeId} data-tooltip-content="nº submissões pendentes" data-tooltip-place="bottom" className="col-1 d-flex align-items-center justify-content-center">
                            <span className="badge br-10 w-2rem" style={{backgroundColor:`rgba(var(--bs-primary-rgb),1)`}}>{props.adminChallenge.submitted}</span>
                            <Tooltip id={`submitted-tooltip-`+props.adminChallenge.challengeId} />
                        </div>
                        <div data-tooltip-id={`accepted-tooltip-`+props.adminChallenge.challengeId} data-tooltip-content="nº submissões aceites" data-tooltip-place="bottom" className="col-1 d-flex align-items-center justify-content-center">
                            <span className="badge br-10 w-2rem" style={{backgroundColor:`rgba(var(--bs-success-rgb),1)`}}>{props.adminChallenge.accepted}</span>
                            <Tooltip id={`accepted-tooltip-`+props.adminChallenge.challengeId} />
                        </div>
                        <div data-tooltip-id={`rejected-tooltip-`+props.adminChallenge.challengeId} data-tooltip-content="nº submissões rejeitadas" data-tooltip-place="bottom" className="col-1 d-flex align-items-center justify-content-center">
                            <span className="badge br-10 w-2rem" style={{backgroundColor:`rgba(var(--bs-warning-rgb),1)`}}>{props.adminChallenge.rejected}</span>
                            <Tooltip id={`rejected-tooltip-`+props.adminChallenge.challengeId} />
                        </div>
                    </div>
                </div>
            </a>
        </>
    )
}