import { useEffect, useState } from "react";

export const Badge: React.FC<{ section: number, number : number, name : string, isSmallScreen : boolean}> = (props) => {

    const [sectionClass, setSectionClass] = useState('');
    const [badgeText, setBadgeText] = useState(<>{(props.number === 1) ? `CUC` : props.number}</>);
    const [badgeClass, setBadgeClass] = useState('');
    const [timeoutId, setTimeoutId] = useState<any>(null);
    const [badgeWidth, setBadgeWidth] = useState('17pt');

    useEffect(()=>{
        setBadgeWidth(props.isSmallScreen ? '17pt' : '25pt');
    }, [props.isSmallScreen]);

    useEffect(()=>{
        switch (props.section) {
            case 1:
                setSectionClass('badge-lobitos');
                break;
            case 2:
                setSectionClass('badge-exploradores');
                break;
            case 3: 
                setSectionClass('badge-pioneiros');
                break;
            case 4:
                setSectionClass('badge-caminheiros');
                break;
        }
    }, [props.section]);

    const handleMouseEnter = () => {
        setBadgeClass('badge-seccao-open');
        setBadgeWidth((props.name.length/2 + 2) + 'rem');
        setTimeoutId(
            setTimeout(() => {
                setBadgeText((props.number === 1) ? <>CUC<br/>{props.name}</> : <>{props.number}<br/>{props.name}</>);
            }, 250)
        );
        return () => clearTimeout(timeoutId);
    }

    const handleMouseExit = () => {
        setBadgeText(<>{(props.number === 1) ? `CUC` : props.number}</>);
        setBadgeWidth(props.isSmallScreen ? '17pt' : '25pt');
        setBadgeClass('');
        clearTimeout(timeoutId);
    }

    return (
        <div className={`d-flex justify-content-center align-items-center text-info badge-seccao ${badgeClass} ${sectionClass}`} style={{width:`${badgeWidth}`}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseExit}>
            <span className="fw-semibold badge-text">{badgeText}</span>
        </div>
    )
}