import { AdminChallenge } from "./AdminChallenge";
import { Challenge } from "./Challenge";

import { useEffect, useState } from "react";
import UserChallengesModel from "../models/UserChallengesModel";
import { AreaIcon } from "./AreaIcon";
import { useOktaAuth } from "@okta/okta-react";
import AdminChallengesModel from "../models/AdminChallengesModel";

export const Area: React.FC<{ area : number, challenges : any, level : number, setChallengeId : any}> = (props) => {
    const [userChallenges, setUserChallenges] = useState<UserChallengesModel[]>([]);
    const [adminChallenges, setAdminChallenges] = useState<AdminChallengesModel[]>([]);
    const [title, setTitle] = useState("");

    const {authState} = useOktaAuth();

    useEffect(() => {
        if (authState?.accessToken?.claims.userType === "admin"){
            const loadedAdminChallenges: AdminChallengesModel[] = [];
            for(const key in props.challenges){
                loadedAdminChallenges.push({
                    challengeId: props.challenges[key].challengeId,
                    challengeTitle: props.challenges[key].title,
                    submitted: props.challenges[key].submitted,
                    accepted: props.challenges[key].accepted,
                    rejected: props.challenges[key].rejected,
                });
            }
            setAdminChallenges(loadedAdminChallenges);
        } else {
            const loadedUserChallenges: UserChallengesModel[] = [];
            for(const key in props.challenges){
                loadedUserChallenges.push({
                    challengeId: props.challenges[key].challengeId,
                    challengeTitle: props.challenges[key].challengeTitle,
                    stateId: props.challenges[key].stateId,
                    stateLabel: props.challenges[key].stateLabel,
                });
            }
            setUserChallenges(loadedUserChallenges);
        }
    },[props.challenges, authState]);
    
    return (
        <div className="accordion accordion-desafios mb-3" role="tablist" id={`accordion-${props.area}`}>
            <div className="accordion-item">
                <h2 className="accordion-header" role="tab">
                    <button className="accordion-button collapsed position-relative" type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-${props.area} .item-1`} aria-expanded="false" aria-controls={`#accordion-${props.area} .item-1`}>
                        <AreaIcon area={props.area} setTitle={setTitle}/>
                        <strong className="text-primary mr-3">{title}</strong>
                        {authState?.accessToken?.claims.userType === "admin" ?
                            <span className="badge position-absolute right-5 br-10 w-2rem" style={{backgroundColor:`rgba(var(--bs-primary-rgb),1)`}}>{adminChallenges.length}</span>
                            :
                            <span className="badge position-absolute right-5 br-10" style={{backgroundColor:`rgba(var(--bs-primary-rgb),${0.5+(props.level*0.1)})`}}>Nível {props.level < 6 ? props.level : `Completo`}&nbsp;</span>
                        }
                    </button>
                </h2>
                <div className="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent={`#accordion-${props.area}`}>
                    <div className="accordion-body">
                    {authState?.accessToken?.claims.userType === "admin" ?
                        <>
                            {adminChallenges.length > 0 ? 
                                <>
                                    {adminChallenges.map(adminChallenge => {
                                        return (
                                            <AdminChallenge key={adminChallenge.challengeId} adminChallenge={adminChallenge} setChallengeId={props.setChallengeId}/>
                                        );
                                    })}
                                </>
                                :
                                <div className="m-3">
                                    <p>
                                        Ainda não existem desafios para esta área
                                    </p>
                                </div>
                            }
                        </>
                        :
                        <>
                            {userChallenges.length > 0 ? 
                                <>
                                    {userChallenges.map(userChallenge => {
                                        return (
                                            <Challenge key={userChallenge.challengeId} userChallenge={userChallenge} setChallengeId={props.setChallengeId}/>
                                        );
                                    })}
                                </>
                                :
                                <div className="m-3">
                                    <p>
                                        Ainda não existem desafios para esta área
                                    </p>
                                </div>
                            }
                        </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}